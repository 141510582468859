<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      pre-select-filter
      :filter-names="filtersName"
      search-label="search"
      :status-options="statusOptions"
      telematic-rows
      vertical-scroll
      @filterOption="onFiltersChange"
    >
      <template v-slot:chasis_no="{ item }">
        <td>
          <div
            v-if="$admin.hasAccessTo('vehicle-telematics-detail.list')"
            class="open-link"
          >
            <!-- @click="() => goToDetailPage(item)" -->
            <!-- :to="{
                name: 'telematic_details',
                params: { imei: item.iot_imei },
                query: getQueryParams,
              }" -->
            <router-link :to="goToDetailPage(item)">
              {{ item.chasis_no }}
            </router-link>
          </div>
          <div v-else>
            {{ item.chasis_no }}
          </div>
        </td>
      </template>

      <template v-slot:soc="{ item }">
        <td>
          <span v-if="item.battery_slot_type == 'fixed'">
            <span
              primary
              icon
              class="plain-text"
            >
              <!-- class="plain-text link" -->
              <!-- @click="goToBatteryDiagnostic(item)" -->
              <v-icon>mdi-lightning-bolt</v-icon>

              {{ item.soc }}
            </span>
          </span>

          <span v-if="item.battery_slot_type == 'swappable'">
            <span v-if="item.vehicle_type == 'two_wheeler'">
              <span
                primary
                icon
                class="plain-text"
              >
                <!-- class="plain-text link" -->
                <!-- @click="goToBatteryDiagnostic(item)" -->
                <v-icon>mdi-lightning-bolt</v-icon>
                {{ item.soc }}
              </span>
            </span>
            <span v-if="item.vehicle_type == 'three_wheeler'">
              <v-menu
                class="menu-list"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    primary
                    icon
                    v-bind="attrs"
                    class="plain-text"
                    v-on="on"
                  >
                    <v-icon>mdi-lightning-bolt</v-icon>
                    {{ item.soc }}
                  </v-btn>
                </template>
                <v-list>
                  <!-- <v-list-item class="d-block link-hover"> -->
                  <v-list-item class="d-block">
                    <v-list-item-title
                      v-if="item.soc_b1 !== 'N/A'"
                      :class="item.battery_bms_ids[0] ? 'active' : 'not-active'"
                    >
                      <!-- @click="
                        goToBatteryDiagnostic(
                          item,
                          item.battery_bms_ids[0],
                          item.battery_ids[0]
                        )
                      " -->
                      <v-icon>{{ checkbatteryStatus(item.soc_b1) }}</v-icon>
                      {{ item.soc_b1 ?? "0%" }}
                    </v-list-item-title>
                    <v-list-item-title
                      v-if="item.soc_b2 !== 'N/A'"
                      :class="item.battery_bms_ids[1] ? 'active' : 'not-active'"
                    >
                      <!-- @click="
                        goToBatteryDiagnostic(
                          item,
                          item.battery_bms_ids[1],
                          item.battery_ids[1]
                        )
                      " -->
                      <v-icon>{{ checkbatteryStatus(item.soc_b2) }}</v-icon>
                      {{ item.soc_b2 ?? "0%" }}
                    </v-list-item-title>
                    <v-list-item-title
                      v-if="item.soc_b3 !== 'N/A'"
                      :class="item.battery_bms_ids[2] ? 'active' : 'not-active'"
                    >
                      <!-- @click="
                        goToBatteryDiagnostic(
                          item,
                          item.battery_bms_ids[2],
                          item.battery_ids[2]
                        )
                      " -->
                      <v-icon>{{ checkbatteryStatus(item.soc_b3) }}</v-icon>
                      {{ item.soc_b3 ?? "0%" }}
                    </v-list-item-title>
                    <v-list-item-title
                      v-if="item.soc_b4 !== 'N/A'"
                      :class="item.battery_bms_ids[3] ? 'active' : 'not-active'"
                    >
                      <!-- @click="
                        goToBatteryDiagnostic(
                          item,
                          item.battery_bms_ids[3],
                          item.battery_ids[3]
                        )
                      " -->
                      <v-icon>{{ checkbatteryStatus(item.soc_b4) }}</v-icon>
                      {{ item.soc_b4 ?? "0%" }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
          </span>
        </td>
      </template>

      <template v-slot:soh="{ item }">
        <td>
          <span v-if="item.soh == 'N/A'">{{ item.soh }}</span>
          <span v-else>
            <v-menu
              class="menu-list"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  primary
                  icon
                  v-bind="attrs"
                  class="plain-text"
                  v-on="on"
                >
                  <v-icon>mdi-lightning-bolt</v-icon>
                  {{ item.soh }}
                </v-btn>
              </template>
              <v-list
                v-if="
                  ![item.soc_b1, item.soc_b2, item.soc_b3, item.soc_b4].every(
                    (r) => r == null
                  )
                "
              >
                <v-list-item class="d-block">
                  <v-list-item-title v-if="item.soh_b1 !== 'N/A'">
                    <v-icon>{{ checkbatteryStatus(item.soh_b1) }}</v-icon>
                    {{ item.soh_b1 }}
                  </v-list-item-title>
                  <v-list-item-title v-if="item.soh_b2 !== 'N/A'">
                    <v-icon>{{ checkbatteryStatus(item.soh_b2) }}</v-icon>
                    {{ item.soh_b2 }}
                  </v-list-item-title>
                  <v-list-item-title v-if="item.soh_b2 !== 'N/A'">
                    <v-icon>{{ checkbatteryStatus(item.soh_b3) }}</v-icon>
                    {{ item.soh_b3 }}
                  </v-list-item-title>
                  <v-list-item-title v-if="item.soh_b4 !== 'N/A'">
                    <v-icon>{{ checkbatteryStatus(item.soh_b4) }}</v-icon>
                    {{ item.soh_b4 }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </td>
      </template>
      <template v-slot:cycle_count="{ item }">
        <td>
          <div v-if="item.vehicle_type == 'three_wheeler'">
            <span>
              B1: <b>{{ item.cycle_count_b1 }}</b> |&nbsp;
            </span>
            <span>
              B2: <b>{{ item.cycle_count_b2 }}</b> |&nbsp;
            </span>

            <span>
              B3: <b>{{ item.cycle_count_b3 }}</b> |&nbsp;
            </span>
            <span>
              B4: <b>{{ item.cycle_count_b4 }}</b>
            </span>
          </div>
          <div v-else>
            B1: <b>{{ item.cycle_count_b1 }}</b>
          </div>
        </td>
      </template>

      <template v-slot:position="{ item }">
        <v-icon
          v-if="item.position"
          class="mr-2"
          small
          @click="showVehiclePosition(item)"
        >
          mdi-eye
        </v-icon>
        <span v-else> N/A</span>
      </template>
      <template v-slot:status="{ item }">
        <div :class="item.status">
          <v-icon>mdi-circle</v-icon>
        </div>
      </template>
      <template v-slot:synced_at="{ item }">
        <div
          class="sync-column"
          :class="
            item.vehicle_meta?.check_failed_reasons?.length > 0
              ? 'text-danger'
              : ''
          "
          @click="isShowSyncDetail = !isShowSyncDetail"
        >
          <span v-if="isShowSyncDetail">{{
            item.synced_at_detail | formatDate
          }}</span>
          <span v-else>{{ item.synced_at }}</span>
        </div>
      </template>
      <template v-slot:actions="{ item }">
        <div>
          <v-btn
            x-small
            color="primary-icon-btn"
            :disabled="item.is_demo_vehicle"
            @click="downloadCSV(item)"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <!-- <v-card>
            <v-text-field
              v-model="options.search"
              dense
              clearable
              :label="$t('select')"
              @change="fetchData"
            />
          </v-card> -->
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <!-- <v-toolbar
            flat
            class="action-toolbar"
          > -->

        <refresh-button
          v-if="list.length > 0"
          :is-loading="isLoading"
          @reload="fetchData"
        />

        <!-- </v-toolbar> -->
      </template>
      <!-- Action Modals -->
    </ListingLayout>
    <!--  -->
    <vehicle-position-modal
      v-if="positionModal"
      :is-visible-dialog="positionModal"
      :toggle-modal="togglePositionModal"
      :position="vehicleTelmatic"
    />
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () => import("@/components/base/RefreshButton.vue"),
    //
    vehiclePositionModal: () => import("./utils/VehiclePositionModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      //
      statusOptions: [
        { id: "active", name: "Active" },
        { id: "inactive", name: "Inactive" },
        { id: "offline", name: "Offline" },
      ],
      //
      timer: null,
      isShowSyncDetail: false,
      vehicleTelmatic: {},
      positionModal: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "customerVehicles/getIsLoadingCustomerVehicles",
      meta: "customerVehicles/getCustomerVehiclesMeta",
      headersList: "customerVehicles/getHeadersList",
      list: "customerVehicles/getCustomerVehiclesList",
    }),
    _headers() {
      var headerArr = [];
      this.headersList.forEach((header) => {
        headerArr.push({
          // text: this.$t(header.key),
          text: header.heading,
          sortable: false,
          value: header.key,
          width: "10%",
        });
      });

      return headerArr;
    },

    filtersName() {
      if (this.authUser.isAdmin) {
        return ["search", "customer", "make", "model", "status"];
      } else {
        return ["search", "make", "model", "status"];
      }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
    "options.product": {
      handler: function () {
        this.fetchHeaders();
      },
    },
    "options.tenant_id": {
      handler: function () {
        this.fetchHeaders();
      },
    },
  },

  beforeDestroy() {
    const params = {
      name: "vehicle_telematics",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);

    //
    // this.$store.commit("customerVehicles/SET_CUSTOMERVEHICLES", []);
    // this.$store.commit("customerVehicles/SET_HEADERS", []);
    clearInterval(this.timer);
  },
  // mounted() {
  //   this.timer = setInterval(() => {
  //     this.fetchData();
  //     // if (this.options.tenant_id !== undefined) {
  //     //   this.$store.dispatch("customerVehicles/list", this.options);
  //     // }
  //   }, 30000);
  // },
  methods: {
    async fetchHeaders() {
      await this.$store.dispatch("customerVehicles/headersList", {
        product: this.options.product,
        fleetId: this.options.fleetId,
        oem_b2b_ids: this.options.oem_b2b_ids,
        tenant_id: this.options.tenant_id,
      });
    },
    async fetchData() {
      await this.$store
        .dispatch("customerVehicles/list", this.options)
        .then(() => {
          // if (this.options.page > (this.customerVehiclesMeta?.lastPage || 1)) {
          //   this.options.page = 1;
          // }
        });
    },
    //
    checkbatteryStatus(status) {
      if (status <= 20) {
        return "mdi-battery-charging-20";
      } else if (status <= 50) {
        return "mdi-battery-charging-50";
      } else if (status <= 80) {
        return "mdi-battery-charging-80";
      } else {
        return "mdi-battery-charging-100";
      }
    },
    showVehiclePosition(vehicleTelematics) {
      //   this.selectedRowId = vehicleTelematics.id;
      // this.vehicleTelmatic = [vehicleTelematics.lng, vehicleTelematics.lat];
      this.vehicleTelmatic = {
        lat: vehicleTelematics.lat,
        lng: vehicleTelematics.lng,
      };
      this.togglePositionModal();
      vehicleTelematics.is_open = true;
      this.selectedrow = vehicleTelematics;
      // this.itemRowBackground(vehicleTelematics);
    },
    togglePositionModal() {
      this.selectedrow ? (this.selectedrow.is_open = false) : "";
      return (this.positionModal = !this.positionModal);
    },

    downloadCSV(item) {
      // const params = {
      //   imei: item.iot_imei,
      // };
      // this.$store
      //   .dispatch("customerVehicles/downloadReport", params)
      //   .then((res) => {
      //     this.$store.dispatch("alerts/success", res.body.sucess_msg);
      //   })
      //   .catch((err) => {
      //     this.$store.dispatch("alerts/error", err?.response?.data?.message);
      //   });
      window.open(item.csv_url, "_blank");
    },
    goToDetailPage(item) {
      const params = { ...this.options };
      Object.keys(params).forEach((key) => {
        if (params[key] == null) {
          params[key] = undefined;
        }
      });
      return {
        name: "telematic_details",
        params: { imei: item.iot_imei },
        query: params,
      };
    },
    // goToDetailPage(item) {
    //   const params = {
    //     ...this.options,
    //     // product: this.options.product,
    //     // fleetId: this.options.fleetId ?? undefined,
    //     // oem_b2b_ids: this.options.oem_b2b_ids ?? undefined,
    //     // tenant_id: this.options.tenant_id,
    //     // search: this.options.search ?? undefined,
    //     // customer: this.options.user_type ?? undefined,
    //     // businessID: this.options.oem_b2b_id ?? undefined,
    //     // make_id: this.options.md_make_id ?? undefined,
    //     // model_id: this.options.md_model_id ?? undefined,
    //     // status: this.options.status ?? undefined,
    //     // page: this.options.page,
    //     // perPage: this.options.itemsPerPage,
    //   };
    //   return this.$router.push({
    //     name: "telematic_details",
    //     params: { imei: item.iot_imei },
    //     query: params,
    //   });
    // },
    // goToBatteryDiagnostic(item, id, bt_id) {
    //   const params = {
    //     vehicle_id: item.vehicle_id,
    //     product: this.options.product,
    //     fleetId: this.options.fleetId ?? undefined,
    //     oem_b2b_ids: this.options.oem_b2b_ids ?? undefined,
    //     tenant_id: this.options.tenant_id,
    //     battery_id: bt_id ?? item.battery_id,
    //     type: item.vehicle_type,
    //     search: this.options.search ?? undefined,
    //     customer: this.options.user_type ?? undefined,
    //     businessID: this.options.oem_b2b_id ?? undefined,
    //     make_id: this.options.md_make_id ?? undefined,
    //     model_id: this.options.md_model_id ?? undefined,
    //     status: this.options.status ?? undefined,
    //     page: this.options.page,
    //     perPage: this.options.itemsPerPage,
    //   };

    //   if (item.vehicle_type == "three_wheeler") {
    //     // query.bmsId = 9240629
    //     params.bmsId = id;
    //   }
    //   return this.$router.push({
    //     name: "battery_diagnostics",
    //     params: { imei: item.iot_imei },
    //     query: params,
    //   });
    // },
  },
};
</script>
<style scoped>
.plain-text {
  background-color: transparent !important;
  color: inherit !important;
}
.link {
  color: #20a390 !important;
}
</style>
<style lang="sass" scoped>
.link-hover
  .v-list-item__title
    cursor : pointer
    &:hover
      color: #20a390 !important
      i
        color: #20a390 !important

.sync-column
  cursor: pointer
  transition: all 0.5s ease
  &:hover
    color: #20a390 !important

.not-active
  pointer-events: none !important

.active
  i
    color: #4CAF50 !important

.inactive
  i
    color: #f0ae38 !important
.offline
  i
    color: #ff0000 !important
</style>
